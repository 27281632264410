export const GET_INVOICES_PENDING = 'GET_INVOICES_PENDING';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';

export const GET_RECEIPTS_PENDING = 'GET_RECEIPTS_PENDING';
export const GET_RECEIPTS_SUCCESS = 'GET_RECEIPTS_SUCCESS';
export const GET_RECEIPTS_FAILURE = 'GET_RECEIPTS_FAILURE';

export const GET_USER_PENDING = 'GET_USER_PENDING';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const UPDATE_USER_MAIL_SUCCESS = 'UPDATE_USER_MAIL_SUCCESS';
export const UPDATE_USER_MAIL_FAILURE = 'UPDATE_USER_MAIL_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';

export const SOCIAL_LOGIN_USER_PENDING = 'SOCIAL_LOGIN_USER_PENDING';
export const LOGIN_USER_PENDING = 'LOGIN_USER_PENDING';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const CLEAR_LOGIN_USER_FAILURE_ERROR = 'CLEAR_LOGIN_USER_FAILURE_ERROR';

export const LOGIN_APPLE_USER_SUCCESS = 'LOGIN_APPLE_USER_SUCCESS';
export const LOGIN_APPLE_USER_FAILURE = 'LOGIN_APPLE_USER_FAILURE';

export const SIGN_IN_USER_OTP_SUCCESS = 'SIGN_IN_USER_OTP_SUCCESS';
export const SIGN_IN_USER_OTP_VOUCHER_SUCCESS = 'SIGN_IN_USER_OTP_VOUCHER_SUCCESS';
export const SIGN_IN_USER_OTP_FAILURE = 'SIGN_IN_USER_OTP_FAILURE';
export const CLEAR_SIGN_IN_USER_OTP_FAILURE_ERROR = 'CLEAR_SIGN_IN_USER_OTP_FAILURE_ERROR';

export const REGISTER_USER_PENDING = 'REGISTER_USER_PENDING';
export const CLEAR_REGISTER_USER_FAILURE_ERROR = 'CLEAR_REGISTER_USER_FAILURE_ERROR';

export const REGISTER_USER_OTP_FAILURE = 'REGISTER_USER_OTP_FAILURE';

export const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS';

export const SET_USER_AS_AUTHENTICATED = 'SET_USER_AS_AUTHENTICATED';

/**
 * @deprecated use FSA
 */
export const GET_CATEGORIES_PENDING = 'GET_CATEGORIES_PENDING';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';

export const GET_CATEGORY_PENDING = 'GET_CATEGORY_PENDING';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';

export const GET_ENTRY_CONTENT_PENDING = 'GET_ENTRY_CONTENT_PENDING';
export const GET_ENTRY_CONTENT_SUCCESS = 'GET_ENTRY_CONTENT_SUCCESS';
export const GET_ENTRY_CONTENT_FAILURE = 'GET_ENTRY_CONTENT_FAILURE';

export const GET_NEW_CONTENT_PENDING = 'GET_NEW_CONTENT_PENDING';
export const GET_NEW_CONTENT_SUCCESS = 'GET_NEW_CONTENT_SUCCESS';
export const GET_NEW_CONTENT_FAILURE = 'GET_NEW_CONTENT_FAILURE';

export const GET_COURSE_PENDING = 'GET_COURSE_PENDING';
export const GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS';
export const GET_COURSE_FAILURE = 'GET_COURSE_FAILURE';

export const GET_SINGLE_PENDING = 'GET_SINGLE_PENDING';
export const GET_SINGLE_SUCCESS = 'GET_SINGLE_SUCCESS';
export const GET_SINGLE_FAILURE = 'GET_SINGLE_FAILURE';

export const TRACK_PLAYER = 'TRACK_PLAYER';
export const STOP_PLAYER = 'STOP_PLAYER';
export const SET_PLAYER_PROCESS = 'SET_PLAYER_PROCESS';
export const SET_PLAYER_STATUS = 'SET_PLAYER_STATUS';

export const CREATE_PROCESS_PENDING = 'CREATE_PROCESS_PENDING';
export const CREATE_PROCESS_SUCCESS = 'CREATE_PROCESS_SUCCESS';
export const CREATE_PROCESS_FAILURE = 'CREATE_PROCESS_FAILURE';

export const GET_UNIT_URL_PENDING = 'GET_UNIT_URL_PENDING';
export const GET_UNIT_URL_SUCCESS = 'GET_UNIT_URL_SUCCESS';
export const GET_UNIT_URL_FAILURE = 'GET_UNIT_URL_FAILURE';

export const REDEEM_CODE_PENDING = 'REDEEM_CODE_PENDING';
export const REDEEM_CODE_SUCCESS = 'REDEEM_CODE_SUCCESS';
export const REDEEM_CODE_FAILURE = 'REDEEM_CODE_FAILURE';
export const CLEAR_UNLOCKCODE_MESSAGES = 'CLEAR_UNLOCKCODE_MESSAGES';

export const RETRIEVE_ALERTS_PENDING = 'RETRIEVE_ALERTS_PENDING';
export const RETRIEVE_ALERTS_FAILURE = 'RETRIEVE_ALERTS_FAILURE';
export const RETRIEVE_ALERTS_SUCCESS = 'RETRIEVE_ALERTS_SUCCESS';
export const ALERT_TOGGLE_VISIBILITY = 'ALERT_TOGGLE_VISIBILITY';

export const CHECK_CONFIRM_MAIL_CODE_PENDING = 'CHECK_CONFIRM_MAIL_CODE_PENDING';
export const CHECK_CONFIRM_MAIL_CODE_FAILURE = 'CHECK_CONFIRM_MAIL_CODE_FAILURE';
export const CHECK_CONFIRM_MAIL_CODE_SUCCESS = 'CHECK_CONFIRM_MAIL_CODE_SUCCESS';

export const REVOKE_CONFIRM_MAIL_CODE_PENDING = 'REVOKE_CONFIRM_MAIL_CODE_PENDING';
export const REVOKE_CONFIRM_MAIL_CODE_FAILURE = 'REVOKE_CONFIRM_MAIL_CODE_FAILURE';
export const REVOKE_CONFIRM_MAIL_CODE_SUCCESS = 'REVOKE_CONFIRM_MAIL_CODE_SUCCESS';

export const CHANGE_REFERER_AFTER_SUCCESS_AUTHENTICATION = 'CHANGE_REFERER_AFTER_SUCCESS_AUTHENTICATION';
export const CLEAR_REFERER_AFTER_SUCCESS_AUTHENTICATION = 'CLEAR_REFERER_AFTER_SUCCESS_AUTHENTICATION';

export const CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_PENDING = 'CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_PENDING';
export const CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_SUCCESS = 'CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_SUCCESS';
export const CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_FAILURE = 'CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_FAILURE';

//Instructions für neue Koops:
// 1. Benne diese Datei am Ende CamelCase KKName.js
// 2. Meta Pagetitle bitte in src->locales->metaPageTitle.js einfügen.
// 3. urlActiveAdmin Pagetitle bitte in src->utils->constants->healthInsuranceCooperations.js einfügen.
// Das ist wichtig für den AutenticationContainer, ThemeProvider und den useHealthInsuranceCompanyCheckoutText Hook.
// 4. ClientKey bekommst du vom vom BE
//////////
//metaPagetitle: 'kkName | Balloon',
//urlActiveAdmin: '/meine-krankenkasse oder mkk oder aok-bayern',
//////////////

export const TEXT = {
  clientKey: '(___VOM BE ERFRAGEN___)',
  headerText: {
    headline: 'Deine Reise mit Balloon und der (___KRANKENKASSEN-NAME___)',
    subline:
      'Starte jetzt den Kurs "(___KURS-NAME___)" und sichere dir im Anschluss (___ZUGRIFFSLÄNGE APPINHALTE AB FREISCHALTUNG___) Vollzugriff auf die komplette Balloon Bibliothek.',
    button: 'Jetzt Starten',
    altText: '(___KRANKENKASSENNAME___) Logo',
    logoLink: 'https://(___KK-WEBSITELINK___)',
    addOnText: 'Ein Online-Coaching von Balloon'
  },
  checkmarkText: {
    headline: 'Balloon macht dein Leben leichter.',
    subline_1:
      'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. Versicherte der (___KRANKENKASSEN-NAME___) erwartet in der App ein spezielles Kursprogramm zur Stressreduktion durch Achtsamkeit. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
    subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
    checkmark_1: 'Techniken für Anfänger und \nFortgeschrittene',
    checkmark_2: '10 Minuten am Tag, im \nHomeoffice oder unterwegs',
    checkmark_3: 'Herausforderungen mit Resilienz begegnen',
    altText: 'Kurs Antistress-Coaching'
  },
  ballooniText: {
    headline: 'Für Versicherte der (___KRANKENKASSEN-NAME___)\n ist der Kurs kostenlos.',
    ballooni_1:
      'Registriere dich einfach unten auf dieser Website. Beachte: Du musst (___XXX%___) des Kurses absolvieren und darfst dein Präventionsbudget in diesem Jahr noch nicht ausgeschöpft haben.',
    ballooni_2:
      'Lade dir anschließend die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs „(___KURS-NAME___)“ gilt für (___KURS-ZUGRIFFSLÄNGE___).',
    ballooni_3:
      'Absolviere den Kurs vollständig innerhalb von (___KURSABSCHLUSS MUSS ERREICHT WERDEN INNERHALB VON___) und erhalte dein Teilnahmezertifikat als Nachweis für die (___KRANKENKASSEN-NAME___) sowie deinen Vollzugriff auf alle Inhalte der Balloon Bibliothek für (___ZUGRIFFSLÄNGE APPINHALTE AB FREISCHALTUNG___).'
  },
  trustText: { headline: 'Balloon ist bekannt aus:' },
  formText: {
    headline: 'Registrierung für Versicherte\nder (___KRANKENKASSEN-NAME___)',
    infoText:
      'Registriere dich hier mit deiner (___KRANKENKASSEN-NAME___) - Versichertennummer und schalte den Kurs “(___KURS-NAME___)” für (___KURS-ZUGRIFFSLÄNGE___) frei. Bei Fragen wende dich gerne an\n',
    contactMail: '(___KRANKENKASSEN-NAME___)@balloonapp.de',
    placeholderNum: '(___KRANKENKASSEN-NAME___)-Versichertennummer*',
    placeholderAge: '(optional) Alter',
    placeholderGender: '(optional) Geschlecht',
    placeholderName: 'Name *',
    placeholderMail: 'E-Mail-Adresse *',
    placeholderDateOfBirth: 'Geburtsdatum* (TT.MM.JJJJ)',
    personalDataSync:
      'Ich bin damit einverstanden, dass meine persönlichen Daten, die ich hier im Formular angegeben habe, sowie mein Kursfortschritt an die (___KRANKENKASSEN-NAME___) übermittelt werden.',
    insuranceNumberInfo: 'Deine (___KRANKENKASSEN-NAME___)-Versichertennummer findest du auf deiner Krankenkassenkarte.'
  },

  refundBox: {
    aboTitle: '(___KURS-NAME___)',
    infoText: 'inkl. (___ZUGRIFFSLÄNGE APPINHALTE AB FREISCHALTUNG___) Balloon'
  }
};

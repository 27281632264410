import { sendCancellationFailure, sendCancellationSuccess } from 'redux/actions';
import { put } from 'redux-saga/effects';
import { sendCancellationApiRequest } from 'api/balloon';

export function* sendCancellationSaga({
  payload: { cancellationDateType, cancellationType, product, requestedCancellationAt, email, cancellationReason }
}) {
  try {
    const response = (yield sendCancellationApiRequest({
      cancellationDateType,
      cancellationType,
      product,
      requestedCancellationAt,
      email,
      cancellationReason
    })).data;

    if (response.errors) {
      yield put(sendCancellationFailure(response.errors));
      return;
    }

    yield put(sendCancellationSuccess({ ...response.data.requestCancellation }));
  } catch (error) {
    yield put(sendCancellationFailure(error));
  }
}

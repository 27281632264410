import React from 'react';
import { MainPage } from 'Container';
import { Container, Row, Col, List, Headline, Paragraph, Button, Link } from 'Common';
import { TEXT } from 'locales/translations';
import birdImg from 'images/thank_you/angry_bird.png';
import { ROUTES } from 'utils/constants';
import { Card } from '@missionme/mime_elements';

export const TerminateUserAboFailed = () => {
  return (
    <MainPage>
      <Container>
        <Row>
          <Col
            xs={{ size: '12', offset: '0' }}
            sm={{ size: '12', offset: '0' }}
            md={{ size: '10', offset: '1' }}
            lg={{ size: '8', offset: '2' }}
          >
            <Card className="card payment-error__card d-block text-center">
              <Headline level={2} className="text-pink-c500">
                {TEXT.terminateAboError.title}
              </Headline>
              <Paragraph>{TEXT.terminateAboError.copy}</Paragraph>
              <Paragraph>{TEXT.terminateAboError.supportCopy}</Paragraph>
              <br />
              <img className="mx-auto d-block" src={birdImg} alt="Aufgeregter Vogel" />
              <br />
              <Link to={ROUTES.terminate}>
                <Button color="pink">{TEXT.terminateAboError.btn}</Button>
              </Link>
            </Card>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
    </MainPage>
  );
};

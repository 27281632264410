export const TEXT = {
  clientKey: 'BIG_DIREKT',
  headerText: {
    headline: 'Deine Reise mit Balloon und der BIG direkt gesund',
    subline:
      'Starte jetzt den Kurs "Stressfrei & achtsam leben" und sichere dir im Anschluss 12 Monate Vollzugriff auf die komplette Balloon Bibliothek.',
    button: 'Jetzt Starten',
    altText: 'BIG direkt gesund Logo',
    logoLink: 'https://www.big-direkt.de/',
    addOnText: 'Ein Online-Coaching von Balloon'
  },
  checkmarkText: {
    headline: 'Balloon macht dein Leben leichter.',
    subline_1:
      'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. Versicherte der BIG erwartet in der App ein spezielles Kursprogramm zur Stressreduktion durch Achtsamkeit. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
    subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
    checkmark_1: 'Techniken für Anfänger und Fortgeschrittene',
    checkmark_2: '10 Minuten am Tag, im Homeoffice oder\nunterwegs',
    checkmark_3: 'Herausforderungen mit Resilienz begegnen',
    altText: 'Kurs Antistress-Coaching'
  },
  ballooniText: {
    headline: 'Für Versicherte der BIG\nist der Kurs kostenlos.',
    ballooni_1:
      'Registriere dich auf dieser Seite bei Balloon: Gib deinen Namen, deine E-Mail-Adresse, deine Versichertennummer und dein Geburtsdatum an.',
    ballooni_2:
      'Lade dir die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs "Stressfrei & achtsam leben" gilt für 6 Monate.',
    ballooni_3:
      'Absolviere den Kurs vollständig und erhalte anschließend ein Teilnahme-Zertifikat sowie 12 Monate Vollzugriff auf die Balloon-App. Als BIG-Versicherter darfst du pro Kalenderjahr an zwei Präventions-Programmen teilnehmen.'
  },
  trustText: { headline: 'Balloon ist bekannt aus:' },
  formText: {
    headline: 'Registrierung für Versicherte von\nBIG direkt gesund',
    infoText:
      'Registriere dich hier mit deiner BIG-Versichertennummer und schalte den Kurs "Stressfrei & achtsam leben" für 6 Monate frei. Bei Fragen wende dich gerne an\n',
    contactMail: 'bigdirektgesund@balloonapp.de',
    placeholderNum: 'BIG direkt gesund-Versichertennummer*',
    placeholderDateOfBirth: 'Geburtsdatum* (TT.MM.JJJJ)',
    placeholderName: 'Vor- und Nachname *',
    placeholderMail: 'E-Mail-Adresse *',
    personalDataSync:
      'Ich bin damit einverstanden, dass meine persönlichen Daten (Name, Versichertennummer und die Anzahl der absolvierten Kurseinheiten) an die BIG direkt gesund übermittelt werden.',
    insuranceNumberInfo: 'Deine BIG direkt gesund -Versichertennummer findest du auf deiner Krankenkassenkarte.'
  },

  refundBox: {
    aboTitle: 'Stressfrei &\nachtsam leben',
    infoText: 'inkl. 12 Monate Balloon'
  }
};

import React from 'react';
import { BorderedSectionContainer, InsuranceContainer, InsuranceLandingPage } from 'Container';
import {
  PartnerLogo,
  ImageParagraph,
  ImageTextRow,
  LogoChain,
  Section,
  HealthRequirementsHint,
  PrivacyPolicyHint,
  InsuranceHeaderSection,
  InsuranceNumberAlreadyExistsButton
} from 'Component';
import { Col, Headline, Icon, Link, List, Paragraph, Row, ScrollButton, Input, Toggle, Image, SelectBox } from 'Common';
import { logoHeaderImage, mobilePhoneImage, successPhoneImage } from 'images/landingpages/aokBayern';
import { TEXT } from 'locales/landingpages/boilerplate'; // <- HIER NUR DEN NAMEN DER KASSE EINTRAGEN
import { flower, heart, thumb } from 'images/landingpages/b2b_clients';
import { LogoBrigitte, LogoFlow, LogoGesundLeben } from 'images/trust_band';
import { colors } from 'Theme';

export const BoilerplateLandingPage = () => {
  const { formText, headerText, checkmarkText, ballooniText, trustText, clientKey } = TEXT;

  // noinspection DuplicatedCode
  return (
    <InsuranceLandingPage>
      <InsuranceHeaderSection className="header-section">
        <Headline level={1}>{headerText.headline} </Headline> <Paragraph className="intro">{headerText.subline}</Paragraph>
        <ScrollButton type="inverted" color="blue" to="form-section" offset={-50}>
          {headerText.button}
        </ScrollButton>
        <PartnerLogo imageSrc={logoHeaderImage} altText={headerText.altText} logoLink={headerText.logoLink} />
        <Paragraph className="add-on-text">{headerText.addOnText}</Paragraph>
      </InsuranceHeaderSection>
      <Section backgroundColor="white" className="checkmark-section" dataCyName="checkmark-section">
        <Row>
          <Col xs="12" md="7" lg="6">
            <Headline level={2}>{checkmarkText.headline}</Headline>
            <Paragraph>{checkmarkText.subline_1}</Paragraph>
            <Paragraph>{checkmarkText.subline_2}</Paragraph>
            <List icon={<Icon color={[colors.blue.c200, colors.blue.c600]} size={{ sm: 32, md: 32, lg: 40 }} name="Checkmark" />}>
              {[checkmarkText.checkmark_1, checkmarkText.checkmark_2, checkmarkText.checkmark_3]}
            </List>
          </Col>
          <Col xs="12" md="5" lg="6" className="image">
            <Image src={mobilePhoneImage} alt="Kursbeispiel Mobil" className="image-course-phone" />
          </Col>
        </Row>
      </Section>
      <BorderedSectionContainer topBorder className="text-center" dataCyName="image-text-row-section">
        <Headline level={2}>{ballooniText.headline}</Headline>
        <ImageTextRow>
          <ImageParagraph dataCyName="paragraph-image-1" image={thumb}>
            {ballooniText.ballooni_1}
          </ImageParagraph>
          <ImageParagraph dataCyName="paragraph-image-2" image={flower}>
            {ballooniText.ballooni_2}
          </ImageParagraph>
          <ImageParagraph dataCyName="paragraph-image-3" image={heart}>
            {ballooniText.ballooni_3}
          </ImageParagraph>
        </ImageTextRow>
      </BorderedSectionContainer>
      <Section backgroundColor="white" verticalPadding="small" className="brands-section text-centerd-none d-md-block">
        <LogoChain
          headline={<Headline level={5}>{trustText.headline}</Headline>}
          logos={[
            <LogoBrigitte primaryColor={colors.blueGrey.c500} />,
            <LogoFlow primaryColor={colors.blueGrey.c500} />,
            <LogoGesundLeben primaryColor={colors.blueGrey.c500} secondaryColor={colors.white.c500} />
          ]}
        />
      </Section>
      <Section backgroundColor="white" verticalPadding="small" className="form-section" dataCyName="form-section">
        <InsuranceContainer b2bClientKey={clientKey} defaultValues={{ personalDataSync: false }}>
          <InsuranceContainer.FormView>
            <div className="header">
              <Headline level={2}>{formText.headline}</Headline>
              <Paragraph className="small">
                {formText.infoText}
                <Link href={`mailto:${formText.contactMail}`}>{formText.contactMail}</Link>
              </Paragraph>{' '}
            </div>
            <InsuranceContainer.Form
              defaultValues={{ personalDataSync: false, gender: 'unknown' }}
              optionalFields={{ gender: true, age: true }}
            >
              {({ register }) => (
                <>
                  <Input {...register('name')} placeholder={formText.placeholderName} />
                  <Input {...register('email')} placeholder={formText.placeholderMail} type="email" />
                  <Input {...register('insuranceNumber')} placeholder={formText.placeholderNum} />
                  <InsuranceNumberAlreadyExistsButton />
                  <Input {...register('dateOfBirth')} placeholder={formText.placeholderDateOfBirth} />
                  <Input {...register('age')} placeholder={formText.placeholderAge} />
                  <SelectBox {...register('gender')} placeholder={formText.placeholderGender} />
                  <Paragraph className="extra-small">{formText.insuranceNumberInfo}</Paragraph>
                  <Toggle {...register('healthCheck')} className="mb-3" id="health-terms-toggle">
                    <HealthRequirementsHint />
                  </Toggle>
                  <Toggle {...register('termsCheck')} className="mb-3" id="privacy-toggle">
                    <PrivacyPolicyHint />
                  </Toggle>
                  <Toggle {...register('personalDataSync')} className="mb-3" id="personal-sync-toggle" dataCyName="personal-data-sync">
                    <Paragraph className="extra-small personal-data-text">{formText.personalDataSync}</Paragraph>
                  </Toggle>
                </>
              )}
            </InsuranceContainer.Form>
          </InsuranceContainer.FormView>
          <InsuranceContainer.OtpView />
          <InsuranceContainer.SuccessView>
            <Image src={successPhoneImage} alt="Antistress-Coaching-Kurs" />
          </InsuranceContainer.SuccessView>
        </InsuranceContainer>
      </Section>
    </InsuranceLandingPage>
  );
};

import React from 'react';
import { TEXT } from 'locales/translations';

export const HealthInsuranceCompanyRefundTable = ({ company }) => {
  const { refundCheck } = TEXT;
  return (
    <div className="content_table_kk_check d-flex flex-column justify-content-start">
      <table>
        <thead>
          <tr>
            <th colSpan={2}>
              {refundCheck.selected.calcBox.refund} {company.reductionRate}%
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{refundCheck.selected.calcBox.price}</td>
            <td className="text-right">75,00 €</td>
          </tr>
          <tr>
            <td>
              {refundCheck.selected.calcBox.refund} {company.reductionRate} % (nach Kurs-Absolvierung)
            </td>
            <td className="text-right">
              -
              {company.reductionRate === 100
                ? '75,00'
                : company.refunds.toLocaleString('de-DE', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}{' '}
              €
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>{refundCheck.selected.calcBox.yourShare}</td>
            <td className="text-right">
              {company.reductionRate === 100
                ? '0,00'
                : (75 - company.refunds).toLocaleString('de-DE', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}{' '}
              €
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

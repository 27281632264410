import * as React from 'react';
import Style from './HealthAdvisorPage.style';
import { CollaborationBenefit, CollaborationOffer, HealthAdvisorFaq, InquirySection } from 'components';
import { colors } from 'Theme';
import { LogoBrigitte, LogoFlow, LogoGesundLeben } from 'images/trust_band';
import { LogoChain, Section } from 'Component';
import { Headline } from 'Common';
import { TEXT } from 'locales/translations';

// TODO: @TERENCE remove eventually
import '@missionme/mime_elements/dist/public/Css/Global.css';
import '@missionme/mime_elements/dist/public/Css/GlobalColors.css';
import '@missionme/mime_elements/dist/public/Css/BootstrapUtils.css';

const HealthAdvisorPage = () => {
  return (
    <Style>
      <CollaborationOffer />
      <CollaborationBenefit />
      <InquirySection />
      <HealthAdvisorFaq />
      <Section backgroundColor="green" verticalPadding="small" className="text-center d-none d-md-block">
        <LogoChain
          headline={<Headline level={5}>{TEXT.trustText.headline}</Headline>}
          logos={[
            <LogoBrigitte primaryColor={colors.white.c500} />,
            <LogoFlow primaryColor={colors.white.c500} />,
            <LogoGesundLeben primaryColor={colors.white.c500} secondaryColor={colors.green.c500} />
          ]}
        />
      </Section>
    </Style>
  );
};

export default HealthAdvisorPage;

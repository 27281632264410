import { Col, Container, Link, Row, ScrollButton } from 'Common';
import {
  HealthInsuranceCheckoutBox,
  HealthInsuranceCompanyRefundTable,
  HealthInsuranceCooperationBox,
  SelectHealthInsuranceCompany
} from 'Component';
import { HealthBenefitLink, HealthInstructions } from 'components/Landingpages/HealthInsurance';
import { Section } from 'Container';
import { useHealthInsuranceCompanyCheckoutText } from 'hookApi/useHealthInsuranceCompanyCheckoutText';
import BorisBalloonie from 'images/home/BallonieBoris@2x.png';
import {
  balloonCheckout1x,
  balloonCheckout2x,
  balloonCheckout3x,
  balloon_intro1x,
  balloon_intro2x,
  balloon_intro3x
} from 'images/landingpages/health_insurance';
import { TEXT } from 'locales/translations';
import React, { useState } from 'react';
import DecorativeImage from '../../DecorativeImage';
import Style from './HealthInsurancePage.style';

export const HealthInsurancePage = () => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const cooperationCompany = useHealthInsuranceCompanyCheckoutText(selectedCompany?.cooperationUrl);
  const { healthHeader, healthIntro, healthExpert, borisSection, refundCheck, refundBox } = TEXT;

  return (
    <Style>
      <Section className="balloon-top-right">
        <Container>
          <Row>
            <Col xs={{ size: 12 }} md={{ size: 7 }}>
              <h1 className="h1_kk text-blueGrey-c500">{healthHeader.headline}</h1>
              <p className="p_xl_kk mb-sm-6 me-sm-7 me-md-0 mb-md-0">{healthHeader.subline_1}</p>
              <ScrollButton
                color="pink"
                className="btn-w-auto mt-3 mb-3 mt-md-5"
                to="section-checkout"
                dataCyName="health-insurance-scroll-to-form"
              >
                {healthHeader.ctaBtn}
              </ScrollButton>
            </Col>
            <Col xs={{ size: 12 }} md={{ size: 7 }}>
              <HealthBenefitLink classNames="btn-w-auto mt-3 mt-md-0" />
            </Col>
          </Row>
        </Container>
      </Section>
      <Section backgroundColor="lightGrey" className="py-5 py-md-6">
        <Container>
          <Row>
            <Col xs={{ size: 12 }} md={{ size: 6 }}>
              <h2 className="h2_kk h2_bold_kk text-blueGrey-c500 mb-4 d-block d-md-none text-center">{healthIntro.headline}</h2>
              <p className="p_xl_kk text-center text-md-left">{healthIntro.text_1}</p>
              <p className="p_xl_kk text-center text-md-left">{healthIntro.text_2}</p>
              <p className="p_xl_kk text-center text-md-left">{healthIntro.text_3}</p>
            </Col>
            <Col xs={{ size: 12 }} md={{ size: 6 }}>
              <div className="text-center d-flex flex-column h-100">
                <h2 className="h2_kk h2_bold_kk text-blueGrey-c500 mb-4 d-none d-md-block ">Komm zur Ruhe!</h2>
                <div className="mt-5 mt-md-0">
                  <ScrollButton className="btn-w-auto mt-5 mt-md-0" to="section-checkout">
                    Jetzt Loslegen
                  </ScrollButton>
                </div>

                <img
                  className="img-fluid mt-auto"
                  srcSet={`${balloon_intro1x} 165w, ${balloon_intro2x} 330w, ${balloon_intro3x} 495w`}
                  src={balloon_intro1x}
                  alt="Balloon entspannt auf einer Bank"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section backgroundColor="lightGrey" className="py-6 py-md-8" id="boris-the-expert">
        <Container>
          <Row>
            <Col xs={{ size: 12 }} md={{ size: 4 }}>
              <DecorativeImage
                src={BorisBalloonie}
                alt={borisSection.alt}
                className="img-fluid img_balloon_expert_kk text-center d-block mx-auto mb-4"
              />
            </Col>
            <Col xs={{ size: 12, offset: 0 }} md={{ size: 7, offset: 1 }}>
              <h2 className="h2_kk text-center text-md-left text-blueGrey-c500 mb-4 mb-md-5">
                {healthExpert.headline_1}
                <span className="h2_bold_kk d-block">{healthExpert.headline_2}</span>
              </h2>
              <p className="p_xl_kk text-center text-md-left">{healthExpert.text}</p>

              <div className="mt-5 d-flex justify-content-center">
                <ScrollButton className="btn-w-auto" color="pink" to="section-checkout">
                  {healthExpert.ctaBtn}
                </ScrollButton>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section backgroundColor="lightGrey" className="py-6 py-md-8" id="section-checkout">
        <Container>
          <Row>
            <Col xs={{ size: 12 }} md={{ size: 6 }} lg={{ size: 6 }} className="kk_info_box">
              <div className="d-flex flex-column me-0 me-lg-4 kk-info-content" id="kk-info-content">
                {selectedCompany ? (
                  selectedCompany.cooperationPartner && cooperationCompany ? (
                    <>
                      <h2 className="h2_kk text-center text-md-right text-blueGrey-c500 mb-5">{refundCheck.selected.general.headline}</h2>
                      <p className="p_lg_kk h2_bold_kk  text-center text-md-right text-blueGrey-c500">
                        Für {selectedCompany.name} {refundBox.info}
                      </p>

                      <p className="text-center text-md-right text-blueGrey-c500">{refundCheck.selected.general.subline}</p>
                      <Link className="text-center text-md-right" onClick={() => setSelectedCompany(null)}>
                        {refundCheck.selected.general.btnText}
                      </Link>
                    </>
                  ) : (
                    <>
                      <h2 className="h2_kk text-center text-md-right text-blueGrey-c500 mb-5">{refundCheck.selected.other.headline}</h2>
                      <p className="p_lg_kk h2_bold_kk  text-center text-md-right ">
                        {selectedCompany.name} Versicherte bekommen {selectedCompany.reductionRate}%
                        {refundCheck.selected.general.refundText}
                        <br />
                        {refundCheck.selected.general.time}
                      </p>
                      <p className="text-center text-md-right">{refundCheck.selected.general.subline}</p>
                      {selectedCompany.cooperationUrl && (
                        <p className="text-center text-md-right px-2">
                          <strong>{refundCheck.selected.general.notice}</strong> {refundCheck.selected.general.member}
                          {selectedCompany.name} {refundCheck.selected.general.info}
                          <a href={selectedCompany.cooperationUrl}>{selectedCompany.cooperationUrl}</a>{' '}
                          {refundCheck.selected.general.info_2}
                        </p>
                      )}
                      <Link className="mb-2 text-center text-md-right" onClick={() => setSelectedCompany(null)}>
                        {refundCheck.selected.general.btnText}
                      </Link>
                      <HealthInsuranceCompanyRefundTable company={selectedCompany} />
                    </>
                  )
                ) : (
                  <>
                    <h2 className="h2_kk text-center text-md-right text-blueGrey-c500 px-2 mb-3">{refundCheck.headline}</h2>
                    <p className="text-center text-md-right">{refundCheck.text}</p>
                    <p className="text-center text-md-right">{refundCheck.subline}</p>
                    <SelectHealthInsuranceCompany onChange={setSelectedCompany} />
                  </>
                )}
              </div>
              {(!selectedCompany || cooperationCompany) && (
                <img
                  className="img_balloon_checkout_kk d-none d-md-block"
                  srcSet={`${balloonCheckout1x} 165w, ${balloonCheckout2x} 330w, ${balloonCheckout3x} 495w`}
                  src={balloonCheckout1x}
                  alt="Balloon über den Wolken"
                />
              )}
            </Col>
            <Col
              xs={{ size: 12 }}
              sm={{ size: 10, offset: 1 }}
              md={{ size: 6, offset: 0 }}
              lg={{ size: 5, offset: 1 }}
              className="text-center kk_info_box"
            >
              {selectedCompany?.cooperationPartner ? (
                <HealthInsuranceCooperationBox cooperationCompanyProps={cooperationCompany.refundBox} />
              ) : (
                <HealthInsuranceCheckoutBox />
              )}
            </Col>
          </Row>
        </Container>
      </Section>
      <HealthInstructions />
    </Style>
  );
};

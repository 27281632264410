import { useState, useEffect, useMemo } from 'react';
import { mapRouteToCompanyKey } from 'utils/constants';

const loadTextForCooperation = async (cooperation) => {
  try {
    const module = await import(`locales/landingpages/${cooperation}`);
    return module.TEXT.refundBox;
  } catch (err) {
    throw new Error(`Fehler beim Laden der Texte für die Kooperation: ${cooperation}`);
  }
};

export const useHealthInsuranceCompanyCheckoutText = (url) => {
  const [text, setText] = useState(null);
  const [_, setError] = useState(null);

  useEffect(() => {
    if (!url) return;

    const route = url.split('/').pop();
    const companyKey = mapRouteToCompanyKey[route];

    if (!companyKey) {
      setError(`Unbekannte Route: ${route}`);
      setText(null);
      return;
    }

    const fetchText = async () => {
      try {
        const loadedText = await loadTextForCooperation(companyKey);
        setText(loadedText);
      } catch (err) {
        setText(null);
        console.error(
          `Fehler beim Laden der Texte für die Kooperation: ${companyKey}. Prüfe, ob die Datei existiert, oder evtl nicht korrekt benannt ist!`,
          err
        );
      }
    };
    fetchText();
  }, [url]);

  const { aboTitle, infoText } = text || {};

  const route = useMemo(() => {
    if (!url) {
      return '';
    }
    const parsedUrl = new URL(url);
    return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
  }, [url]);

  return {
    refundBox: {
      route,
      aboTitle,
      infoText
    }
  };
};

export const TEXT = {
  clientKey: 'VIACTIV2024',
  headerText: {
    headline: 'Deine Reise mit Balloon \nund der VIACTIV',
    subline:
      'Starte jetzt den Kurs "Stressreduktion durch Achtsamkeit" \n und sichere dir im Anschluss 6 Monate Vollzugriff auf die komplette Balloon Bibliothek.',
    button: 'Jetzt Starten',
    altText: 'VIACTIV Logo',
    logoLink: 'https://www.viactiv.de/',
    addOnText: 'Ein Online-Coaching von Balloon'
  },
  checkmarkText: {
    headline: 'Balloon macht dein Leben leichter.',
    subline_1:
      'Die App Balloon sorgt für Gesundheit, Klarheit und Gelassenheit. Für Kunden der VIACTIV haben wir einen Kurs entwickelt, mit dem du weniger gestresst durch das Leben gehen kannst. In den audiobasierten Kursübungen wird Wissen mit gewohnheitsbildender Praxis verbunden.',
    subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
    checkmark_1: 'Techniken für Anfänger und \nFortgeschrittene',
    checkmark_2: '10 Minuten am Tag, im \nHomeoffice oder unterwegs',
    checkmark_3: 'Herausforderungen mit \nResilienz begegnen',
    altText: 'Kurs Antistress-Coaching'
  },
  ballooniText: {
    headline: 'Für VIACTIV-Versicherte \nist der Kurs kostenlos.',
    ballooni_1:
      'Registriere dich einfach unten auf dieser Website. Beachte: Du musst mind. 100% des Kurses absolvieren und darfst dein Präventionsbudget von 550€ im Jahr noch nicht ausgeschöpft haben.',
    ballooni_2:
      'Lade dir anschließend die Balloon-App herunter und melde dich mit \ndeiner E-Mail-Adresse an. \nDein kostenloser Zugriff auf den Kurs "Stressreduktion durch Achtsamkeit"\n gilt für ein Jahr.',
    ballooni_3:
      'Absolviere den Kurs vollständig \ninnerhalb von 6 Monaten und \nerhalte anschließend \ndein Zertifikat zur Teilnahme \nam VIACTIV-Bonusprogramm. \nGenieße außerdem nach \nKursabschluss 6 Monate \nVollzugriff auf die gesamte \nBalloon-App.'
  },
  trustText: { headline: 'Balloon ist bekannt aus:' },
  formText: {
    headline: 'Registrierung für Versicherte \nder VIACTIV',
    infoText:
      'Registriere dich hier mit deiner VIACTIV-\nVersichertennummer und schalte den Kurs \n"Stressreduktion durch Achtsamkeit" für ein Jahr \nfrei. Bei Fragen wende dich gerne an \n',
    contactMail: 'viactiv@balloonapp.de',
    placeholderNum: 'VIACTIV-Versichertennummer *',
    placeholderName: 'Name *',
    placeholderMail: 'E-Mail-Adresse *',
    personalDataSync:
      'Ich bin damit einverstanden, dass meine persönlichen Daten (Name, Versichertennummer und die Anzahl der absolvierten Kurseinheiten) an die VIACTIV übermittelt werden.'
  },

  refundBox: {
    aboTitle: 'Stressreduktion durch\nAchtsamkeit (VIACTIV)',
    infoText: 'inkl. 6 Monate Balloon'
  }
};

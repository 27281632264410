export const TEXT = {
  clientKey: 'DAK',
  headerText: {
    headline: 'Deine Reise mit Balloon und der DAK-Gesundheit',
    subline:
      'Starte jetzt den Kurs "DAK Antistress-Coaching \n(achtsamkeitsbasiert)" und sichere dir anschließend 3 Monate \nVollzugriff auf die komplette Balloon-Bibliothek.',
    button: 'Jetzt Starten',
    altText: 'DAK-Gesundheit Logo',
    logoLink: 'https://dak.de',
    addOnText: 'Ein Online-Coaching von Balloon'
  },
  checkmarkText: {
    headline: 'Balloon macht dein Leben leichter.',
    subline_1:
      'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. Versicherte der DAK-Gesundheit erwartet in der App ein spezielles Antistress-Coaching. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
    subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
    checkmark_1: 'Techniken für Anfänger und \nFortgeschrittene',
    checkmark_2: '10 Minuten am Tag, im \nHomeoffice oder unterwegs',
    checkmark_3: 'Herausforderungen mit \nResilienz begegnen',
    altText: 'Kurs Antistress-Coaching'
  },
  ballooniText: {
    headline: 'Für Versicherte der DAK-Gesundheit \nist der Kurs kostenlos.',
    ballooni_1:
      'Registriere dich auf dieser Seite bei Balloon: Gib deinen Namen, deine E-Mail-Adresse und deine Versichertennummer der DAK-Gesundheit an.',
    ballooni_2:
      'Lade dir die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs "DAK Antistress-Coaching (achtsamkeitsbasiert)" gilt für ein Jahr.',
    ballooni_3:
      'Absolviere den Kurs vollständig und erhalte anschließend ein Teilnahmezertifikat sowie 3 Monate Vollzugriff auf die Balloon-App.'
  },
  trustText: { headline: 'Balloon ist bekannt aus:' },
  formText: {
    headline: 'Registrierung für Versicherte \nder DAK-Gesundheit',
    infoText:
      'Registriere dich hier mit deiner \nVersichertennummer der DAK-Gesundheit und schalte den Kurs "DAK Antistress-Coaching (achtsamkeitsbasiert)" \nfür ein Jahr frei. Bei Fragen wende dich gerne an ',
    contactMail: 'dak@balloonapp.de',
    placeholderNum: 'Versichertennummer *',
    placeholderName: 'Name *',
    placeholderMail: 'E-Mail-Adresse *',
    placeholderGender: '(optional) Geschlecht',
    placeholderAge: '(optional) Alter',
    personalDataSync:
      'Ich bin damit einverstanden, dass meine persönlichen Daten (Name, Versichertennummer und die Anzahl der absolvierten Kurseinheiten) an die DAK übermittelt werden.',
    insuranceNumberInfo: 'Deine Versichertennummer findest du auf deiner Krankenkassenkarte.'
  },

  refundBox: {
    aboTitle: 'DAK Antistress-Coaching\n(achtsamkeitsbasiert)',
    infoText: 'inkl. 3 Monate Balloon'
  }
};

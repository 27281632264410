import React, { useEffect, useState } from 'react';
import { Amount, Button, Headline, Paragraph, Spinner, Toggle } from 'Common';
import { ROUTES } from 'utils/constants';
import { TEXT } from 'locales/translations';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PrivacyPolicyLink from 'components/PrivacyPolicyLink';
import TermsOfServiceLink from 'components/TermsOfServiceLink';
import { ResilienceRequirementsLink } from './ResilienceRequirementsLink';
import { AccessibilityLink } from 'Component';
import { getZppCertifiedSubscriptionPlan } from 'redux/query';
import { getSubscriptionPlansRequest, selectSubscriptionPlan } from 'redux/actions';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';
import { colors } from 'Theme';
import Style from './ResilienceCheckoutBox.style';

export const ResilienceCheckoutBox = () => {
  const history = useHistory();
  const plan = useSelector(getZppCertifiedSubscriptionPlan) ?? {};
  const dispatch = useDispatch();
  const [healthRequirementChecked, setHealthRequirementChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    dispatch(getSubscriptionPlansRequest(SUBSCRIPTION_PLAN_GROUPS.NULL));
  }, [getSubscriptionPlansRequest]);

  const handleClick = () => {
    setHealthRequirementChecked(!healthRequirementChecked);
  };

  const handleTermsClick = () => {
    setTermsChecked(!termsChecked);
  };

  const selectedPlan = () => {
    dispatch(selectSubscriptionPlan(plan.id));
    history.push(ROUTES.checkout, { from: history.location.pathname });
  };

  let disabledButton = true;
  if (healthRequirementChecked && termsChecked) {
    disabledButton = false;
  }

  return Object.keys(plan).length !== 0 ? (
    <Style id="section-checkout-box" className="px-5 py-5 px-md-4 px-lg-5 d-flex flex-column align-items-center mx-auto mt-5 mt-md-0">
      <Headline level={4} className="mb-0">
        {plan.title}
      </Headline>
      <Paragraph>{TEXT.resiliencePage.refundBox.infoText}</Paragraph>
      <Headline level={1} className="mb-2 align-self-center">
        <Amount value={plan.chargeAmount} currency={plan.currency} />
      </Headline>

      <Paragraph>{TEXT.resiliencePage.refundBox.interval}</Paragraph>
      <Toggle className="mb-4" checked={healthRequirementChecked} id="switch1" onChange={handleClick}>
        <Paragraph className="small text-start mb-0 ps-2">
          {TEXT.resiliencePage.refundBox.approvalText_1_a} <ResilienceRequirementsLink />{' '}
          {TEXT.resiliencePage.refundBox.approvalText_1_b}
        </Paragraph>
      </Toggle>
      <Toggle className="mb-4" checked={termsChecked} id="switch2" onChange={handleTermsClick}>
        <Paragraph className="small text-start mb-0 ps-2">
          {TEXT.resiliencePage.refundBox.approvalText_2} <TermsOfServiceLink buttonText="AGB" color={colors.green.c500} />{' '}
          {TEXT.resiliencePage.refundBox.approvalText_3} <PrivacyPolicyLink buttonText="Datenschutz" color={colors.green.c500} />{' '}
        </Paragraph>
      </Toggle>
      <div className="text-center">
        <Button className="btn-w-auto mt-auto" onClick={() => selectedPlan()} disabled={disabledButton}>
          {TEXT.resiliencePage.refundBox.ctaBtn}
        </Button>
      </div>

      <div className="info-text mt-4 px-3 px-md-0">
        <Paragraph className="extra-small">
          {TEXT.resiliencePage.refundBox.asteriskText}
          <br />
          Zu den <AccessibilityLink />
        </Paragraph>
      </div>
    </Style>
  ) : (
    <div id="section-checkout-box" className="d-flex flex-column mx-auto mt-auto text-center h-100 info-box justify-content-center">
      <Spinner />
    </div>
  );
};

export const SEND_CANCELLATION_REQUEST = 'SEND_CANCELLATION_REQUEST';
export const SEND_CANCELLATION_SUCCESS = 'SEND_CANCELLATION_SUCCESS';
export const SEND_CANCELLATION_FAILURE = 'SEND_CANCELLATION_FAILURE';

export const sendCancellationRequest = ({
  cancellationDateType,
  cancellationType,
  product,
  requestedCancellationAt,
  email,
  cancellationReason
}) => ({
  type: SEND_CANCELLATION_REQUEST,
  payload: {
    cancellationDateType,
    cancellationType,
    product,
    requestedCancellationAt,
    email,
    cancellationReason
  }
});

export const sendCancellationSuccess = ({ receivedDate, email, confirmationPdfUrl }) => ({
  type: SEND_CANCELLATION_SUCCESS,
  payload: {
    receivedDate,
    email,
    confirmationPdfUrl
  }
});

export const sendCancellationFailure = (error) => ({
  type: SEND_CANCELLATION_FAILURE,
  error
});

export const TEXT = {
  clientKey: 'TK',
  headerText: {
    headline: 'Deine Reise mit Balloon und der Techniker',
    subline:
      'Starte jetzt den Kurs "Stressreduktion durch Achtsamkeit" und sichere dir im Anschluss 12 Monate Vollzugriff auf die komplette Balloon Bibliothek.',
    button: 'Jetzt Starten',
    altText: 'die Techniker Logo',
    logoLink: 'https://www.tk.de/',
    addOnText: 'Ein Online-Coaching von Balloon'
  },
  checkmarkText: {
    headline: 'Balloon macht dein Leben leichter.',
    subline_1:
      'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. Versicherte der Techniker Krankenkasse erwartet in der App ein spezielles Kursprogramm zur Stressreduktion durch Achtsamkeit. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
    subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
    checkmark_1: 'Techniken für Anfänger und Fortgeschrittene',
    checkmark_2: '10 Minuten am Tag, im Homeoffice oder\nunterwegs',
    checkmark_3: 'Herausforderungen mit Resilienz begegnen',
    altText: 'Kurs Stressreduktion durch Achtsamkeit'
  },
  ballooniText: {
    headline: 'Für Versicherte der Techniker ist der Kurs kostenlos.',
    ballooni_1:
      'Registriere dich einfach unten auf dieser Website. Beachte: Du musst 100% des Kurses absolvieren und darfst dein Präventionsbudget in diesem Jahr noch nicht ausgeschöpft haben.',
    ballooni_2:
      'Lade dir anschließend die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs "Stressreduktion durch Achtsamkeit" gilt für 12 Monate.',
    ballooni_3:
      'Absolviere den Kurs vollständig innerhalb von 6 Monaten und erhalte dein Teilnahmezertifikat als Nachweis für die Techniker Krankenkasse sowie deinen Vollzugriff auf alle Inhalte der Balloon Bibliothek für 12 Monate.'
  },
  trustText: { headline: 'Balloon ist bekannt aus:' },
  formText: {
    headline: 'Registrierung für Versicherte\n der Techniker Krankenkasse',
    infoText:
      'Registriere dich hier und schalte den Kurs "Stressreduktion durch Achtsamkeit" für 12 Monate frei. Bei Fragen wende dich gerne an\n',
    contactMail: 'techniker@balloonapp.de',
    placeholderDateOfBirth: 'Geburtsdatum* (TT.MM.JJJJ)',
    placeholderName: 'Vor- und Nachname *',
    placeholderMail: 'E-Mail-Adresse *',
    personalDataSync:
      'Ich bin damit einverstanden, dass meine persönlichen Daten, die ich hier im Formular angegeben habe, sowie mein Kursfortschritt an die Techniker übermittelt werden.'
  },
  //Special only TK
  authText: {
    headline: 'Mitgliedschaft bei\nder Techniker bestätigen',
    headline_success: 'Mitgliedschaft bestätigt',
    headline_clock: 'Deine Bestätigung ist 12 Minuten lang,\nbis ##TIME## Uhr gültig',
    description:
      'Bestätige hier schnell und unkompliziert deine Mitgliedschaft\nbei der Techniker, um das Registrierungsformular freizuschalten!',
    description_success: 'Jetzt Formular ausfüllen und loslegen',
    authButton: 'Mitgliedschaft bestätigen',
    popupError: 'Das Login-Fenster konnte nicht geöffnet werden. Bitte überprüfe die Popup-Einstellungen deines Browsers.',
    errorHeadline: 'Mitgliedschaft konnte nicht bestätigt werden',
    errorDescription1: 'Tut uns leid, das ist etwas schief gelaufen. Bitte versuche es erneut:',
    errorDescription2:
      'Hier findest du Hilfe zum Anmelden bei der TK und Hilfe zum Thema Leistungsanspruch für Gesundheitskurse bei der TK.',
    errors: {
      defaultError: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut, oder wende dich an unseren Support.',
      NOT_ALLOWED: 'Die Mitgliedschaft konnte nicht bestätigt werden. Bitte versuche es erneut, oder wende dich an unseren Support.'
    }
  },

  refundBox: {
    aboTitle: 'Stressreduktion\ndurch Achtsamkeit',
    infoText: 'inkl. 12 Balloon'
  }
};

import * as types from 'redux/actions/actionTypes';
import { put, takeLatest, all, select } from 'redux-saga/effects';
import {
  getUserPaymentInfosRequest,
  POST_CHECKOUT_SUBSCRIPTION_UPGRADE_REQUEST,
  postCheckoutSubscriptionUpgradeSuccess,
  postCheckoutSubscriptionUpgradeFailure,
  REDEEM_GIFT_COUPON_SUCCESS
} from 'redux/actions';
import { ROUTES } from 'utils/constants';
import { browserHistory } from '../configureStore';
import { getSubscriptionPlanByInterval } from 'redux/query';
import { upgradeSubscriptionApiRequest, upgradeSubscriptionWithCouponApiRequest } from 'api/balloon';

function* updateSubscriptionState() {
  yield put(getUserPaymentInfosRequest({ renewable: false }));
}

function* callUpgradeSubscription() {
  const state = yield select();
  const plan = getSubscriptionPlanByInterval('YEARLY')(state);
  const currentCoupon = plan && plan.coupon ? plan.coupon : undefined;

  try {
    let result = {};
    if (!!currentCoupon) {
      result = yield upgradeSubscriptionWithCouponApiRequest({
        subscriptionPlanId: plan.id,
        couponCode: currentCoupon.code
      });
    } else {
      result = yield upgradeSubscriptionApiRequest({ subscriptionPlanId: plan.id });
    }

    if (result.data.errors) {
      yield put(postCheckoutSubscriptionUpgradeFailure(result.data.errors));
    } else {
      yield all([put(postCheckoutSubscriptionUpgradeSuccess()), put(getUserPaymentInfosRequest({ renewable: false }))]);
      browserHistory.push(ROUTES.thankyouupgrade);
    }
  } catch (error) {
    console.error(error);
    yield put(postCheckoutSubscriptionUpgradeFailure(error));
  }
}

function forwardToThankYouWithdrawCanelation() {
  browserHistory.push(ROUTES.thankyou);
}

function* actionWatcher() {
  yield takeLatest(REDEEM_GIFT_COUPON_SUCCESS, updateSubscriptionState);
  yield takeLatest(POST_CHECKOUT_SUBSCRIPTION_UPGRADE_REQUEST, callUpgradeSubscription);
  yield takeLatest(types.CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_SUCCESS, updateSubscriptionState);
  yield takeLatest(types.CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_SUCCESS, forwardToThankYouWithdrawCanelation);
}

export function* initSubscription() {
  yield all([actionWatcher()]);
}

export const ROUTES = {
  appLegacyImprint: '/app-resources/managed-2/HTML/imprint/imprint.html',
  appLegacyPrivacy: '/app-resources/managed-2/HTML/terms/privacy.html',
  appLegacyTerms: '/app-resources/managed-2/HTML/terms/terms.html',
  appResources: '/app-resources',
  bigDirect: '/krankenkasse/bigdirektgesund',
  salusBkk: '/krankenkasse/salusbkk',
  tk: '/krankenkasse/techniker',
  bkk: '/krankenkasse/bertelsmann-bkk',
  bkkShort: '/bertelsmann-bkk',
  mkk: '/krankenkasse/meine-krankenkasse',
  mkkShort: '/meine-krankenkasse',
  buyGift: '/verschenken',
  category: '/category/:slug',
  checkout: '/checkout',
  checkoutConfirm: '/checkout-confirm',
  company: '/unternehmen',
  confirmMail: '/web/login',
  confirmMailCode: '/web/login/:code?',
  contact: '/contact',
  dak: '/krankenkasse/dak',
  aokBayern: '/krankenkasse/aok-bayern',
  dakBgm: '/krankenkasse/dak-bgm',
  dakBgmEve: 'https://www.dak.de/dak/einverstaendniserklaerung-2450448.html#/',
  dakBgmShort: '/dak-bgm',
  dakShort: '/dak',
  deeplinkConfirmMail: '/web/confirm_email_change/:code?',
  email_confirmation: '/email_confirmation',
  email_confirmation_error: '/email_confirmation_error',
  externalFacebookPrivacy: '/legal/facebook-privacy',
  externalHealth: '/legal/health',
  externalLegacyFacebookPrivacy: '/facebook-privacy',
  externalLegacyHealth: '/health',
  externalLegacyPrivacyPolicy: '/privacy-policy',
  externalLegacyTerms: '/terms',
  externalPrivacy: '/legal/privacy',
  externalPrivacyPolicy: '/legal/privacy-policy',
  externalTerms: '/legal/terms',
  facebook: 'https://www.facebook.com/Balloon/',
  fallbackApp: '/app/*',
  fallbackAppOtp: '/app/login/:code?',
  fallbackAppView: '/view/*',
  gothaer: '/krankenkasse/gothaer',
  gothaerShort: '/gothaer',
  healthAdvisor: '/health-advisor',
  trustPortal: '/security',
  home: '/',
  ikkClassic: '/krankenkasse/ikkclassic',
  hkk: '/krankenkasse/hkk',
  vividaBkk: '/krankenkasse/vividabkk',
  imprint: '/imprint',
  instagram: 'https://www.instagram.com/balloon_meditation/',
  kaifu: '/partner/kaifu',
  kaifuShort: '/kaifu',
  krankenkasse: '/krankenkasse',
  krankenkasseSurveyMaybe: '/krankenkasse/survey-maybe',
  krankenkasseSurveyRight: '/krankenkasse/survey-right',
  krankenkasseSurveyWrong: '/krankenkasse/survey-wrong',
  legal: '/legal',
  legalApp: '/legal/app',
  library: '/bibliothek',
  licht: '/partner/licht',
  lichtShort: '/licht',
  login: '/login',
  openBalloonApp: 'https://www.balloonapp.de/view/course/2C4CCA94B046CE2D755AEA5A14CA1C54D78F82A726E2070246345EA3484023E8',
  othersEve:
    'https://einverstaendniserklaerung.dak.de/prweb/EVE?pyActivity=Data-Portal.SetHashAndShowPortal&hvalue=OL45DA3FEDB0EF5C7520BDA985A65305CA',
  paymenterror: '/paymenterror',
  paymenterror_nostorage: '/payment-referererror',
  plans: '/buy',
  play: '/play',
  podcast: '/podcast',
  profile: '/profile',
  profilePersonalData: '/profile/personal-data',
  profileSubscriptionStatus: '/profile/subscription-status',
  resilience: '/krankenkasse/resilienz',
  signup: '/signup',
  singlePlayer: '/play/single/:slug',
  stress: '/partner/stress',
  stressreduktion: '/stressreduktion',
  stressShort: '/stress',
  terminate: '/terminate',
  terminateSuccess: '/terminate/success',
  terminateFailed: '/terminate/error',
  thankyou: '/thankyou',
  thankyougift: '/thankyougift',
  thankyouloggedout: '/thankyouloggedout',
  thankyoupurchaseassociated: '/thankyoupurchaseassociated',
  thankyouupgrade: '/thankyouupgrade',
  upgrade: '/upgrade',
  upgradeerror: '/upgradeerror',
  viactiv: '/krankenkasse/viactiv',
  viactivShort: '/viactiv',
  boilerplate: '/krankenkasse/boilerplate',
  voucher: '/gutschein',
  voucher_manual: '/gutschein/anleitung',
  webAccessibility: '/legal/web/accessibility',
  webHealth: '/legal/web/health',
  webHealthBenefit: '/legal/web/health-benefit',
  webPrivacy: '/legal/web/privacy',
  webappResources: '/webapp-resources',
  webappLegacyAccessibility: '/webapp-resources/health_accessibility.html',
  webappLegacyHealth: '/webapp-resources/health.html',
  webappLegacyHealthBenefit: '/webapp-resources/health_benefit.html',
  webappLegacyPrivacy: '/webapp-resources/privacy.html',
  webappLegacyTerms: '/webapp-resources/terms.html',
  webTerms: '/legal/web/terms',
  webplayer: '/play/course/:slug',
  withdrawCancelation: '/withdraw-cancelation',
  xing: '/partner/xing-premium',
  xingProJobs: '/partner/xing-projobs',
  xingProJobsShort: '/xing-projobs',
  xingShort: '/xing-premium',
  youtube: 'https://www.youtube.com/channel/UC9gwE0T4ZJAxCekCbeFDgjA',
  google: 'https://play.google.com/store/apps/details?id=com.greenhouse.balloon',
  apple: 'https://apps.apple.com/de/app/balloon-meditation/id1259131200',
  insuranceCooperationAuthentication: '/insurance-cooperation-authentication'
};

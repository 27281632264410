export const TEXT = {
  clientKey: 'DAK_BGM',
  headerText: {
    headline: 'Deine Reise mit Balloon und der DAK-Gesundheit',
    subline:
      'Starte jetzt den Kurs "DAK Antistress-Coaching \n(achtsamkeitsbasiert)" und sichere dir anschließend 3 Monate \nVollzugriff auf die komplette Balloon-Bibliothek.',
    button: 'Jetzt Starten',
    altText: 'DAK-Gesundheit Logo',
    logoLink: 'https://dak.de',
    addOnText: 'Ein Online-Coaching von Balloon'
  },
  checkmarkText: {
    headline: 'Balloon macht dein Leben leichter.',
    subline_1:
      'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. In der App erwartet dich ein spezielles Antistress-Coaching. Es ist Teil des Betrieblichen Gesundheitsmanagements der DAK-Gesundheit. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
    subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
    checkmark_1: 'Techniken für Anfänger und \nFortgeschrittene',
    checkmark_2: '10 Minuten am Tag, im \nHomeoffice oder unterwegs',
    checkmark_3: 'Herausforderungen mit \nResilienz begegnen',
    altText: 'Kurs Antistress-Coaching'
  },
  ballooniText: {
    headline: 'Kostenlos im\n Betrieblichen Gesundheits-\nmanagement der \nDAK-Gesundheit enthalten',
    ballooni_1: 'Registriere dich auf dieser Seite bei Balloon: Gib deinen Namen, deine E-Mail-Adresse und den Namen deiner Firma an.',
    ballooni_2:
      'Lade dir die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs "DAK Antistress-Coaching (achtsamkeitsbasiert)" gilt für ein Jahr.',
    ballooni_3: 'Absolviere den Kurs vollständig und erhalte 3 Monate Vollzugriff auf die Balloon-App.'
  },
  trustText: { headline: 'Balloon ist bekannt aus:' },
  formText: {
    leftButton: 'DAK-Gesundheit',
    rightButton: 'Andere Versicherung',
    headline: 'Registrierung mit dem \nBetrieblichen \nGesundheitsmanagement \nder DAK-Gesundheit',
    infoText:
      'Registriere dich hier mit dem Namen deiner Firma und schalte so den Kurs "DAK Antistress-Coaching (achtsamkeitsbasiert)" für ein Jahr frei. Bei Fragen wende dich gerne an ',
    contactMail: 'dak@balloonapp.de',
    placeholderName: 'Vor- und Nachname *',
    placeholderMail: 'E-Mail-Adresse *',
    placeholderCompanyRequired: 'Firma *',
    postalCode: 'PLZ deines Wohnsitzes *',
    additionalInfoText: 'Wir versuchen, unser Angebot immer weiter zu verbessern. Dabei helfen uns folgende Informationen:',
    placeholderNum: '(optional) Versichertennummer',
    insuranceNumberInfo: 'Deine Versichertennummer findest du auf deiner Krankenkassenkarte.',
    placeholderGender: '(optional) Geschlecht',
    placeholderAge: '(optional) Alter'
  },
  successScreen: {
    newsletterHeadline: 'Wollen Sie auch in Zukunft auf\n dem Laufenden bleiben?',
    newsletterBody:
      'Wir freuen uns, dass Ihnen Ihre Gesundheit so wichtig ist. Mit Ihrer Einwilligung kann Ihnen die DAK-Gesundheit künftig die Leistungen, Extras und Services vorstellen, die ganz persönlich zu Ihnen passen - natürlich kostenlos und unverbindlich.',
    eveButton: 'Einwilligung zur Kontaktaufnahme'
  }
};

import React, { useMemo } from 'react';
import { themeCalm, themePodcast, themeUniversal, themeWork, ThemeProvider as MMThemeProvider } from '@missionme/mime_elements';
import { ROUTES } from 'utils/constants';
import { useLocation } from 'react-router-dom';
import { mapRouteToCompanyKey } from 'utils/constants';
import { getCoopPartnerRoutes } from 'utils';

const workThemes = [ROUTES.company];
const calmThemes = [
  ...(getCoopPartnerRoutes(mapRouteToCompanyKey) || []),
  ROUTES.xing,
  ROUTES.gothaer,
  ROUTES.bkk,
  ROUTES.dakBgm,
  ROUTES.resilience
];
const podcastThemes = [ROUTES.podcast];

export const ThemeProvider = ({ children, theme }) => {
  const location = useLocation().pathname.toLowerCase();

  const _theme = useMemo(() => {
    if (theme) {
      return theme;
    }
    switch (true) {
      case workThemes.includes(location):
        return themeWork;
      case calmThemes.includes(location):
        return themeCalm;
      case podcastThemes.includes(location):
        return themePodcast;
      default:
        return themeUniversal;
    }
  }, [location, theme]);

  return <MMThemeProvider theme={_theme}>{children}</MMThemeProvider>;
};

import {
  REGISTER_USER_REQUEST,
  GET_STORE_INFOS_REQUEST,
  GET_INSURANCE_COMPANIES_REQUEST,
  AUTHENTICATE_WITH_GIS_REQUEST,
  REDEEM_GIFT_COUPON,
  REGISTER_USER_GUEST_REQUEST,
  APPLICATION_BOOTSTRAPPED,
  REGISTER_USER_GUEST_SUCCESS,
  GET_USER_SUBSCRIPTIONS_REQUEST,
  AUTHENTICATE_WITH_GIS_SUCCESS,
  AUTHENTICATE_INSURANCE_COOPERATION_USER_SUCCESS,
  SEND_CANCELLATION_REQUEST
} from 'redux/actions';
import { takeLatest, all } from 'redux-saga/effects';
import { registerUserSaga } from './RegisterUserSaga';
import { registerUserFinalSaga } from './RegisterUserFinalSaga';
import { getStoreInfosSaga } from './getStoreInfosSaga';
import { getInsuranceCompaniesSaga } from './getInsuranceCompanies';
import { authenticateWithGISSaga } from './AuthenticateUserWithGISSaga';
import { redeemGiftCouponSaga } from './redeemGiftCouponSaga';
import { registerUserGuestSaga } from './registerUserGuestSaga';
import { initializeUserSaga } from './initializeUserSaga';
import { setApiTokenSaga } from './setApiTokenSaga';
import { listenInsuranceOtpSaga } from './listenInsuranceOtpSaga';
import { sendCancellationSaga } from './sendCancellationSaga';
import {
  LOGIN_APPLE_USER_SUCCESS,
  LOGIN_USER_SUCCESS,
  SIGN_IN_USER_OTP_SUCCESS,
  SIGN_IN_USER_OTP_VOUCHER_SUCCESS
} from 'redux/actions/actionTypes';
import { getUserSubscriptionsSaga } from './getUserSubscriptionsSaga';

export function* balloonUserWatcher() {
  yield all([
    yield takeLatest(APPLICATION_BOOTSTRAPPED, initializeUserSaga),
    yield takeLatest(REGISTER_USER_GUEST_REQUEST, registerUserGuestSaga),
    yield takeLatest(REGISTER_USER_REQUEST, registerUserSaga),
    yield takeLatest(REGISTER_USER_REQUEST, registerUserFinalSaga),
    yield takeLatest(GET_USER_SUBSCRIPTIONS_REQUEST, getUserSubscriptionsSaga),
    yield takeLatest(GET_STORE_INFOS_REQUEST, getStoreInfosSaga),
    yield takeLatest(REDEEM_GIFT_COUPON, redeemGiftCouponSaga),
    yield takeLatest(SEND_CANCELLATION_REQUEST, sendCancellationSaga),
    yield takeLatest(GET_INSURANCE_COMPANIES_REQUEST, getInsuranceCompaniesSaga),
    yield takeLatest(
      [
        LOGIN_USER_SUCCESS,
        SIGN_IN_USER_OTP_VOUCHER_SUCCESS,
        AUTHENTICATE_INSURANCE_COOPERATION_USER_SUCCESS,
        SIGN_IN_USER_OTP_SUCCESS,
        AUTHENTICATE_WITH_GIS_SUCCESS,
        LOGIN_APPLE_USER_SUCCESS,
        REGISTER_USER_GUEST_SUCCESS
      ],
      setApiTokenSaga
    ),
    yield takeLatest(AUTHENTICATE_WITH_GIS_REQUEST, authenticateWithGISSaga),
    yield takeLatest('@@router/LOCATION_CHANGE', listenInsuranceOtpSaga)
  ]);
}

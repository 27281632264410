export const TEXT = {
  meta: {
    pageTitle: {
      home: 'Meditation per App',
      contact: 'Kontakt',
      imprint: 'Impressum',
      login: 'Anmelden',
      plans: 'Abo-Auswahl',
      checkout: 'Jetzt kaufen | Preise',
      profile: 'Profil',
      profilePersonalData: 'Persönliche Daten | Profil',
      profileSubscriptionStatus: 'Deine Freischaltung | Profil',
      signup: 'Registrieren',
      thankyou: 'Abgeschlossen | Kaufen | Preise',
      thankyougift: 'Abgeschlossen | Kaufen | Preise',
      thankyouloggedout: 'Abgeschlossen | Kaufen | Preise',
      thankyoupurchaseassociated: 'Abgeschlossen | Kaufen | Preise',
      thankyouupgrade: 'Abgeschlossen | Kaufen | Preise',
      paymenterror: 'Fehler | Kaufen | Preise',
      licht: 'Tageslichtlampe TL 100 | BEURER',
      stress: 'StressreleaZer | BEURER',
      buyGift: 'Verschenken',
      library: 'Bibilothek',
      kaifu: 'KAIFU',
      stressreduktion: 'Stressreduktion mit deiner Krankenkasse',
      krankenkasse: 'Stressreduktion mit deiner Krankenkasse',
      voucher: 'Gutschein | Profil',
      podcast: 'Podcast',
      voucher_manual: 'Anleitung | Gutschein | Profil',
      company: 'Für Unternehmen',
      bkk: 'Bertelsmann BKK',
      mkk: 'Meine Krankenkasse mkk',
      hkk: 'hkk | Krankenkaasse',
      vividabkk: 'vivida bkk | Krankenkaasse',
      xing: 'XING Premium',
      xingProJobs: 'XING ProJobs',
      terminate: 'Abo Kündigung',
      terminateSuccess: 'Abo gekündigungen',
      gothaer: 'Gothaer',
      dak: 'DAK Antistress-Coaching',
      dakBgm: 'DAK-BGM Antistress-Coaching',
      bigDirect: 'BIG direkt gesund | Krankenkasse',
      ikkClassic: 'IKK Classic | Krankenkasse',
      salusBkk: 'Salus BKK | Balloon',
      viactiv: 'VIACTIV',
      tk: 'Techniker Krankenkasse',
      facebookPrivacy: 'Datenschutz | Facebook',
      aokBayern: 'AOK Bayern | Balloon',
      boilerplate: 'template | Balloon'
    }
  }
};

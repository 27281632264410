export const TEXT = {
  clientKey: 'MKK',
  headerText: {
    headline: 'Deine Reise mit Balloon und der mkk - meine krankenkasse',
    subline:
      'Starte jetzt den Kurs „Mindful Me: Mein Weg zur inneren Balance” und sichere dir anschließend 6 Monate Vollzugriff auf die komplette Balloon-Bibliothek.',
    button: 'Jetzt Starten',
    altText: 'mkk Logo',
    logoLink: 'https://www.meine-krankenkasse.de/',
    addOnText: 'Ein Online-Coaching von Balloon'
  },
  checkmarkText: {
    headline: 'Balloon macht dein Leben leichter.',
    subline_1:
      'Die App Balloon sorgt für Gesundheit, Klarheit und Gelassenheit. Für Versicherte der "mkk - meine krankenkasse" haben wir einen Kurs entwickelt, der dich dabei unterstützt, weniger gestresst durch das Leben zu gehen. In den Audioübungen wird Wissen mit gewohnheitsbildender Praxis verbunden.',
    subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
    checkmark_1: 'Techniken für Anfänger und \nFortgeschrittene',
    checkmark_2: '10 Minuten am Tag - bequem in der\nApp, du bestimmst wann und wo',
    checkmark_3: 'Stress durch Achtsamkeit\nreduzieren und entspannter leben',
    altText: 'Kurs Antistress-Coaching'
  },
  ballooniText: {
    headline: 'Für mkk Versicherte \nist der Kurs kostenlos.',
    ballooni_1:
      'Registriere dich einfach unten auf dieser Website. Beachte: Du musst 100% des Kurses absolvieren und darfst dein Präventionsbudget in diesem Jahr noch nicht ausgeschöpft haben.',
    ballooni_2:
      'Lade dir anschließend die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs „Mindful Me: Mein Weg zur inneren Balance“ gilt für 6 Monate.',
    ballooni_3:
      'Absolviere den Kurs vollständig und erhalte dein Teilnahmezertifikat als Nachweis für die mkk sowie deinen Vollzugriff auf alle Inhalte der Balloon Bibliothek.'
  },
  trustText: { headline: 'Balloon ist bekannt aus:' },
  formText: {
    headline: 'Registrierung für Versicherte\nder mkk - meine krankenkasse',
    infoText:
      'Registriere dich hier mit deiner mkk - Versichertennummer und schalte den Kurs “Mindful Me: Mein Weg zur inneren Balance” für 6 Monate frei. Bei Fragen wende dich gerne an\n',
    contactMail: 'mkk@balloonapp.de',
    placeholderNum: 'mkk-Versichertennummer*',
    placeholderName: 'Name *',
    placeholderMail: 'E-Mail-Adresse *',
    personalDataSync:
      'Ich bin damit einverstanden, dass meine persönlichen Daten (Name, Versichertennummer und die Anzahl der absolvierten Kurseinheiten) an die mkk übermittelt werden.',
    insuranceNumberInfo: 'Deine mkk-Versichertennummer findest du auf deiner Krankenkassenkarte.'
  },

  refundBox: {
    aboTitle: 'Mindful Me: Mein Weg\n zur inneren Balance',
    infoText: 'inkl. 6 Monate Balloon'
  }
};

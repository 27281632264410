export const TEXT = {
  clientKey: 'AOK_BY',
  headerText: {
    headline: 'Deine Reise mit Balloon und der AOK Bayern',
    subline:
      'Starte jetzt den Kurs "Stressreduktion durch Achtsamkeit" und sichere dir im Anschluss 6 Monate Vollzugriff auf die komplette Balloon Bibliothek.',
    button: 'Jetzt Starten',
    altText: 'AOK-Bayern Logo',
    logoLink: 'https://www.aok.de/pk/bayern',
    addOnText: 'Ein Online-Coaching von Balloon'
  },
  checkmarkText: {
    headline: 'Balloon macht dein Leben leichter.',
    subline_1:
      'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. Versicherte der AOK Bayern erwartet in der App ein spezielles Kursprogramm zur Stressreduktion durch Achtsamkeit. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
    subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
    checkmark_1: 'Techniken für Anfänger und \nFortgeschrittene',
    checkmark_2: '10 Minuten am Tag, im \nHomeoffice oder unterwegs',
    checkmark_3: 'Herausforderungen mit Resilienz begegnen',
    altText: 'Kurs Antistress-Coaching'
  },
  ballooniText: {
    headline: 'Für Versicherte der AOK Bayern\n ist der Kurs kostenlos.',
    ballooni_1:
      'Registriere dich einfach unten auf dieser Website. Beachte: Du musst 100% des Kurses absolvieren und darfst dein Präventionsbudget in diesem Jahr noch nicht ausgeschöpft haben.',
    ballooni_2:
      'Lade dir anschließend die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs „Stressreduktion durch Achtsamkeit“ gilt für 12 Monate.',
    ballooni_3:
      'Absolviere den Kurs vollständig innerhalb von 6 Monaten und erhalte dein Teilnahmezertifikat als Nachweis für die AOK Bayern sowie deinen Vollzugriff auf alle Inhalte der Balloon Bibliothek für 6 Monate.'
  },
  trustText: { headline: 'Balloon ist bekannt aus:' },
  formText: {
    headline: 'Registrierung für Versicherte\nder AOK Bayern',
    infoText:
      'Registriere dich hier mit deiner AOK Bayern - Versichertennummer und schalte den Kurs “Stressreduktion durch Achtsamkeit” für 12 Monate frei. Bei Fragen wende dich gerne an\n',
    contactMail: 'aok-bayern@balloonapp.de',
    placeholderNum: 'AOK Bayern-Versichertennummer*',
    placeholderName: 'Name *',
    placeholderMail: 'E-Mail-Adresse *',
    placeholderDateOfBirth: 'Geburtsdatum* (TT.MM.JJJJ)',
    placeholderAge: '(optional) Alter',
    placeholderGender: '(optional) Geschlecht',
    personalDataSync:
      'Ich bin damit einverstanden, dass meine persönlichen Daten, die ich hier im Formular angegeben habe, sowie mein Kursfortschritt an die AOK Bayern übermittelt werden.',
    insuranceNumberInfo: 'Deine AOK Bayern-Versichertennummer findest du auf deiner Krankenkassenkarte.'
  },

  refundBox: {
    aboTitle: 'Stressreduktion\ndurch Achtsamkeit',
    infoText: 'inkl. 6 Monate Balloon'
  }
};

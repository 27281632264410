import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Button, Col, Paragraph, Container, Link, Row, Spinner, Form, SelectBox, Input } from 'Common';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getSubscriptionPlansRequest, sendCancellationRequest } from 'redux/actions';
import { HappyCloudBalloon } from 'components/images';
import { TextField } from 'Component';
import {
  getCurrentUser,
  getSubscriptionPlans,
  isGuest,
  isSubscriptionPlansLoading,
  hasCancellationRequest,
  isCancellationFailed
} from 'redux/query';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';
import Style from './TerminateUserAbo.style';
import { isValidEmail } from 'utils/validators';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'utils';
import { Headline } from '@missionme/mime_elements';

export const TerminateUserAbo = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isGuestUser = useSelector(isGuest);
  const user = useSelector(getCurrentUser);
  const userMail = user?.email;
  const plans = useSelector(getSubscriptionPlans);
  const isFetching = useSelector(isSubscriptionPlansLoading);
  const isLoading = useSelector(hasCancellationRequest);
  const isFailed = useSelector(isCancellationFailed);

  useEffect(() => {
    dispatch(getSubscriptionPlansRequest(SUBSCRIPTION_PLAN_GROUPS.NULL));
  }, []);

  const [formState, setFormState] = useState({
    cancellationType: 'ON_TIME',
    product: null,
    cancellationDateType: null,
    requestedCancellationAt: null,
    email: null,
    cancellationReason: null
  });
  const [isSending, setIsSending] = useState(false);

  const productOptions = useMemo(() => {
    const options = [];

    plans.forEach((plan) => {
      if (plan.interval !== 'ONETIME' && !plan.zppCertified) {
        options.push({
          value: plan.backendIntervalNaming.toUpperCase(),
          label: plan.title
        });
      }
    });

    return options;
  }, [plans]);

  useEffect(() => {
    if (!isSending || isLoading) {
      return;
    }
    if (!isFailed) {
      history.push(ROUTES.terminateSuccess);
      return;
    }
    history.push(ROUTES.terminateFailed);
  }, [isSending, isLoading, isFailed]);

  const handleOnChange = (data) => {
    setFormState((prevState) => ({ ...prevState, ...data }));
  };

  const validate = () => {
    const newFormState = { ...formState };
    let hasError = false;

    switch (true) {
      case !formState.product: {
        newFormState.product = formState.product || '';
      }
      case !formState.email: {
        newFormState.email = formState.email || '';
      }
      case !formState.cancellationDateType: {
        newFormState.cancellationDateType = formState.cancellationDateType || '';
      }
      case formState.cancellationDateType === 'REQUESTED' && !formState.requestedCancellationAt: {
        newFormState.requestedCancellationAt = formState.requestedCancellationAt || '';
      }
      case formState.cancellationType === 'REASONED' && !formState.cancellationReason: {
        newFormState.cancellationReason = formState.cancellationReason || '';
        hasError = true;
        break;
      }
    }

    setFormState(newFormState);
    return !hasError;
  };

  const handleOnTerminateUserAbo = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();

    if (!validate()) {
      return;
    }

    const params = {
      cancellationType: formState.cancellationType,
      product: formState.product,
      cancellationDateType: formState.cancellationDateType,
      email: formState.email,
      cancellationReason: formState.cancellationReason
    };

    if (params.cancellationDateType === 'REQUESTED') {
      params.requestedCancellationAt = formState.requestedCancellationAt;
    }
    setIsSending(true);
    dispatch(sendCancellationRequest(params));
  };

  return (
    <Style bodyClassName="main-decorated" className="terminate-user-abo">
      <Row>
        <Col xs={{ size: 12, offset: 0 }} lg={{ size: 10, offset: 1 }}>
          <Container>
            <Row>
              <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }}>
                <div className="card-terminate card-email card mb-5 pb-5 px-5">
                  <Row>
                    <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }}>
                      <Headline level={2} className="card-title">
                        Dein Kündigungswunsch
                      </Headline>
                    </Col>
                    <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }}>
                      <div className="newsletter-disclaimer text-start">
                        Wir bedauern sehr, dass Du Dein Abo kündigen möchtest. Wenn wir dir irgendwie weiterhelfen können oder es etwas
                        gibt, dass dich zum Bleiben überzeugen würde, schreibe uns doch gerne eine E-Mail an{' '}
                        <Link href="mailto:info@balloonapp.de">info@balloonapp.de</Link> mit dem Betreff „meine Kündigung“ und lass uns
                        wissen, was wir tun können.{' '}
                      </div>
                    </Col>
                    <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }}>
                      <div className="pb-4 newsletter-disclaimer text-start">
                        Bitte beachte: Wenn du dein Abo im Appstore oder Playstore abgeschlossen hast, musst du es dort kündigen. Hier
                        kannst du nur Abos kündigen, die du über unsere Website gekauft hast.
                      </div>
                    </Col>
                  </Row>
                  {isFetching || isLoading ? (
                    <div className="w-100 d-flex justify-content-center">
                      <Spinner className="m-0" />
                    </div>
                  ) : (
                    <Fragment>
                      <Form hideNativeErrors className="terminate-user-abo-form">
                        <FormGroup row>
                          <Label for="created_at" sm={3}>
                            Eingang des Kündigungwunsches:
                          </Label>
                          <Col className="terminate-created-at-formfield" sm={9}>
                            <Input name="createdAt" disabled defaultValue="DD.MM.JJJJ - HH:MM" />
                            <Paragraph className="date-hint">Datum und Uhrzeit werden automatisch bei Absenden eingetragen.</Paragraph>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="cancellation_type" sm={3}>
                            Art der Kündigung:
                          </Label>
                          <Col sm={9}>
                            <SelectBox
                              id="cancellation_type"
                              onChange={(evt) => handleOnChange({ cancellationType: evt.target.value })}
                              options={[
                                { value: 'ON_TIME', label: 'Fristgemäße Kündigung' },
                                { value: 'REASONED', label: 'aus wichtigem Grund' }
                              ]}
                              value={formState.cancellationType}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="cancellation_type" sm={3}>
                            Zu kündigendes Abonnement:
                          </Label>
                          <Col sm={9}>
                            <SelectBox
                              id="product"
                              valid={!formState.product && formState.product !== null ? false : undefined}
                              feedbackMessage={'Bitte wähle aus, welches Abo du kündigen möchtest.'}
                              onChange={(evt) => handleOnChange({ product: evt.target.value })}
                              options={productOptions}
                              value={formState.product || ''}
                              placeholder={'bitte auswählen'}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="cancellation_date_type" sm={3}>
                            Gewünschter Kündigungszeitpunkt:
                          </Label>
                          <Col sm={formState.cancellationDateType === 'REQUESTED' ? 5 : 9}>
                            <SelectBox
                              id="cancellation_date_type"
                              valid={!formState.cancellationDateType && formState.cancellationDateType !== null ? false : undefined}
                              feedbackMessage={'Bitte wähle aus, welches Abo du kündigen möchtest.'}
                              onChange={(evt) => handleOnChange({ cancellationDateType: evt.target.value })}
                              options={[
                                { label: 'Vor nächster Rechnung', value: 'END_OF_TERM' },
                                { label: 'Frühestmöglich', value: 'EARLIEST' },
                                { label: 'Wunschdatum wählen', value: 'REQUESTED' }
                              ]}
                              value={formState.cancellationDateType || ''}
                              placeholder={'bitte auswählen'}
                            />
                          </Col>
                          {formState.cancellationDateType === 'REQUESTED' && (
                            <Col sm={4}>
                              <TextField
                                id="requested_cancellation_at"
                                type="date"
                                onChange={(requestedCancellationAt) => handleOnChange({ requestedCancellationAt })}
                                className="bg-white"
                                required
                                defaultValue={moment().format('YYYY-MM-DD')}
                                errorMessage={
                                  formState.requestedCancellationAt !== null && !formState.requestedCancellationAt
                                    ? 'Bitte gib ein gültiges Datum an.'
                                    : undefined
                                }
                              />
                            </Col>
                          )}
                        </FormGroup>
                        <FormGroup row>
                          <Label for="email" sm={3}>
                            Deine E-Mail-Adresse*:
                          </Label>
                          <Col sm={9}>
                            <TextField
                              id="email"
                              type="email"
                              name="email"
                              className="bg-white"
                              onChange={(email) => handleOnChange({ email })}
                              placeholder={'Deine E-Mail-Adresse*'}
                              defaultValue={isGuestUser ? '' : userMail || ''}
                              required
                              errorMessage={
                                formState.email !== null && !isValidEmail(formState.email || 'INVALID')
                                  ? 'Bitte gib deine E-Mail-Adresse an.'
                                  : undefined
                              }
                            />
                            <Paragraph className="date-hint">
                              * Bitte gib hier die E-Mail-Adresse an mit der du deinen Zugang gekauft hast, so dass wir deine Kündigung
                              richtig zuordnen können. An diese E-Mail-Adresse senden wir ebenfalls deine Kündigungsbestätigung.
                            </Paragraph>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="cancellation_reason" sm={3}>
                            Kündigungsgrund:
                          </Label>
                          <Col sm={9}>
                            <TextField
                              id="cancellation_reason"
                              type={'textarea'}
                              placeholder={'Kündigungsgrund'}
                              onChange={(cancellationReason) => handleOnChange({ cancellationReason })}
                              errorMessage={
                                formState.cancellationType === 'REASONED' &&
                                formState.cancellationReason !== null &&
                                !formState.cancellationReason
                                  ? 'Bitte gib an, aus welchem wichtigen Grund du außerordentlich kündigen möchtest, damit wir deine Anfrage bearbeiten können.'
                                  : undefined
                              }
                              required={formState.cancellationType === 'REASONED'}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col sm={{ size: 9, offset: 3 }} className="text-end">
                            <Button dataCyName="terminate-button" onClick={handleOnTerminateUserAbo}>
                              JETZT VERBINDLICH KÜNDIGEN
                            </Button>
                          </Col>
                        </FormGroup>
                      </Form>
                    </Fragment>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col sm={12}>
          <HappyCloudBalloon className="ad-tile__illustration mt-2" />
        </Col>
      </Row>
    </Style>
  );
};

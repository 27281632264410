//add routename as key, how its coming from active admin
// Value is also the name of the translation file
export const mapRouteToCompanyKey = {
  viactiv: 'viactiv',
  hkk: 'hkk',
  dak: 'dak',
  'meine-krankenkasse': 'mkk',
  bigdirektgesund: 'bigDirect',
  ikkclassic: 'ikkClassic',
  vividabkk: 'vividaBkk',
  salusbkk: 'salusBkk',
  techniker: 'tk',
  'aok-bayern': 'aokBayern'
};

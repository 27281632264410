import React from 'react';
import { balloon1x, balloon2x, balloon3x } from 'images/landingpages/health_insurance';
import { Amount, Button } from 'Common';
import { AccessibilityLink } from 'Component';
import { TEXT } from 'locales/translations';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getZppCertifiedSubscriptionPlan } from 'redux/query';

export const HealthInsuranceCooperationBox = ({ cooperationCompanyProps }) => {
  const history = useHistory();
  const plan = useSelector(getZppCertifiedSubscriptionPlan) ?? {};
  const { asteriskText } = TEXT.refundBox;
  const { aboTitle, infoText } = cooperationCompanyProps;

  const forwardToPage = () => {
    history.push(cooperationCompanyProps.route + '#anmeldung-krankenkasse');
  };

  return (
    <div id="section-checkout-box" className="plans-box-kk-koops-partner px-3 py-5 pt-0 d-flex flex-column mx-auto mt-5 mt-md-0">
      <img
        className="img_balloon_checkout_dak d-block"
        srcSet={`${balloon1x} 165w, ${balloon2x} 330w, ${balloon3x} 495w`}
        src={balloon1x}
        alt="Balloon umsonst"
      />
      <p className="plans-btn-kk__title mt-md-3">{aboTitle}</p>
      <p className="plans-btn-kk__interval">{infoText}</p>
      <p className="plans-box-kk__amount mb-2 align-self-center">
        <Amount value={0} currency={plan.currency} />
      </p>

      <p className="plans-btn-kk__interval mb-2 align-self-center plans-btn-kk__strike_price">
        <Amount value={plan.chargeAmount} currency={plan.currency} />
      </p>
      <div className="text-center">
        <Button className="mt-auto" onClick={forwardToPage}>
          Jetzt kostenlos starten
        </Button>
      </div>

      <div className="plans-box-kk__info-text mt-4 px-3 px-md-0">
        {asteriskText}
        <br />
        Zu den <AccessibilityLink />
      </div>
    </div>
  );
};

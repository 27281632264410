import { combineReducers } from 'redux';
import { formReducer } from 'react-redux-form';
import * as fromGiftCouponCode from './giftCouponCode';

const formsReducer = combineReducers({
  termsAndConditions: formReducer('entities.forms.termsAndConditions', {}),
  giftCoupon: formReducer('entities.forms.giftCoupon', {}),
  user: formReducer('entities.forms.user', {}),
  login: formReducer('entities.forms.login', {}),
  personalData: formReducer('entities.forms.personalData', {}),
  specialFeature: formReducer('entities.forms.specialFeature', {}),
  insuranceCompanyName: formReducer('entities.forms.insuranceCompanyName', {}),
  b2bContact: formReducer('entities.forms.b2bContact', {})
});

export default formsReducer;

export const getGiftCouponCode = (state) => fromGiftCouponCode.getCode(state.giftCoupon);

import React from 'react';
import { ROUTES } from 'utils/constants';
import { Container, Row, Col, List, Headline, Paragraph, Button, Link } from 'Common';
import { TEXT } from 'locales/translations';
import birdImg from 'images/thank_you/angry_bird.png';

export const PaymentErrorNoStorage = () => {
  return (
    <section className="main-decorated payment-error">
      <Container>
        <Row>
          <Col
            xs={{ size: '12', offset: '0' }}
            sm={{ size: '12', offset: '0' }}
            md={{ size: '10', offset: '1' }}
            lg={{ size: '8', offset: '2' }}
            className="card payment-error__card d-block text-center mt-6 mb-0 mb-sm-7 my-md-7"
          >
            <Headline level={2} className="text-pink-c500">
              {TEXT.paymentErrorNoStorage.title}
            </Headline>
            <Paragraph>{TEXT.paymentErrorNoStorage.copy}</Paragraph>
            <List>
              <>{TEXT.paymentErrorNoStorage.reason1}</>
              <>{TEXT.paymentErrorNoStorage.reason2}</>
            </List>
            <Paragraph>{TEXT.paymentErrorNoStorage.supportCopy}</Paragraph>
            <img className="mx-auto d-block" src={birdImg} alt="Aufgeregter Vogel" />
            <Link to={ROUTES.plans}>
              <Button color="pink">{TEXT.paymentErrorNoStorage.btn}</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
